<template>
    <div >   
        <div class="swp">
            <div class="swpboxs">
                <div class="swpbox" >
                    <div class="swpbox1" v-for="(item,index) in xxdd" :key="index"  :style="swpCss">
                        <div class="swpbox1_l"><img :src="item.img" alt=""></div>
                        <div class="swpbox1_r">
                            <span class="span1">{{item.name}}</span>
                            <span class="span2">{{item.text}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"SwpItem",
    data(){
        return{ 
            xxdd:[
                {img:require("../assets/image/avatar1.png"),name:"黄** 尾号5150 江苏省南京市",text:'这个报告是我查过最准确的！'},
                {img:require("../assets/image/avatar2.png"),name:"沈** 尾号8168 安徽省蚌埠市",text:'我基本上每个月都会来这边查一次'},
                {img:require("../assets/image/avatar3.png"),name:"袁** 尾号7516 河南省南阳市",text:'很好用，资料很详细'},
                {img:require("../assets/image/avatar4.png"),name:"邹** 尾号1102 湖北省孝感市",text:'客服也很细心，会帮我解读'},
                {img:require("../assets/image/avatar2.png"),name:"杨** 尾号1283 湖北省武汉市",text:'很好用的大数据查询平台！'},
                {img:require("../assets/image/avatar1.png"),name:"黄** 尾号5150 江苏省南京市",text:'这个报告是我查过最准确的！'},
                {img:require("../assets/image/avatar2.png"),name:"沈** 尾号8168 安徽省蚌埠市",text:'我基本上每个月都会来这边查一次'},
                {img:require("../assets/image/avatar3.png"),name:"袁** 尾号7516 河南省南阳市",text:'很好用，资料很详细'},
                {img:require("../assets/image/avatar4.png"),name:"邹** 尾号1102 湖北省孝感市</p>",text:'客服也很细心，会帮我解读'}
            ],
            swpCss:null,
            b:2000
        }
    },
    mounted(){
        let a= 0
        setInterval(()=>{
            a+=1.632
            if(a>=9.792){
                a=0
                this.swpCss ="transition-duration:0ms;"
            }else{
                this.swpCss = `transition-duration: 500ms; transform: translateY(-${a}rem);`
            }
        },1500)
    },
    methods:{
        
    }
}
</script>

<style lang="less" scoped>
    .swp{
        width: 100%;
        height: auto;
        background: #fff;
        margin-top:20px;
        border-radius: 35px;
        p{
            width: 100%;
            height: 100px;
            line-height: 100px;
            font-size: 32px;
            font-weight:600;
            // background: red;
            text-align: center;
        }
        .swpboxs{
            width: 100%;
            height: 370px;
            overflow: hidden;
        }
        .swpbox{
            width: 100%;
            height: 360px;

            .swpbox1{
                height: 120px;
                display: flex;
                align-items: center;
                // background: red;
                padding: 0 24px ;
                border-top: 1px solid #e3e3e3;
                .swpbox1_l{
                    img{
                        width: 80px;
                        height: 80px;
                        margin-right:50px;
                        margin-left:25px;
                    }
                }
                .swpbox1_r{
                    display: flex;
                    flex-direction:column;
                    justify-content: center;
                    align-items: flex-start;
                    color: #666;
                    .span1{
                        font-size: 28px;
                    }
                    .span2{
                        font-size: 24px;
                    }
                }
            }
           
        }
    }
</style>
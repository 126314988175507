<template>
  <div class="HB">
    <!-- 购买滚动 -->
    <div class="rollbuy">
      <div class="xxdw">
        <rollbuy></rollbuy>
      </div>
    </div>
    <!-- 信息填写 -->
    <div class="info">
      <!-- title -->
      <p class="title">如接到任何贷款电话都是诈骗</p>
      <div class="inputbox">
        <img src="../../src/assets/Home2/HBuser.png" alt="" />
        <input
          type="text"
          placeholder="输入您的真实姓名"
          maxlength="20"
          v-model="userName"
        />
      </div>
      <div class="inputbox">
        <img src="../../src/assets/Home2/HBphone.png" alt="" />
        <input
          type="text"
          placeholder="输入您的手机号"
          maxlength="11"
          v-model="userPhone"
        />
      </div>
      <div class="inputbox">
        <img src="../../src/assets/Home2/HBuserid.png" alt="" />
        <input
          type="text"
          placeholder="输入您的身份证号"
          maxlength="18"
          v-model="userId"
        />
      </div>
      <!-- 协议1 -->
      <div class="agreement">
        <van-checkbox v-model="agreementChecked" icon-size="14px" />
        <span style="color: 666; margin-left: 10px">我已同意并阅读</span>
        <span style="color: #4772fc" @click="gotoprotocol"
          >《报告查询服务协议》</span
        >
      </div>
      <!-- 协议2 -->
      <div class="agreement" v-if="bestowal != -1 && bestowalContent">
        <van-checkbox v-model="agreementChecked2" icon-size="14px" />
        <!-- <span v-if="bestowal == 2" style="color: 666; margin-left: 10px">{{
          content
        }}</span> -->
        <!--  v-if="
            bestowal == 0 || bestowal == 1 || bestowal == 3 || bestowal == 4
          " -->
        <div
          class="twoAgreement"
          style="color: 666; margin-left: 10px"
          v-if="bestowalContent"
        >
          {{ bestowalContent }}
          <span
            style="color: #4772fc"
            @click="insuranceProtocol"
            v-if="agreementName"
            >《{{ agreementName }}》</span
          >
        </div>
      </div>
      <!-- 占位 -->
      <div style="height: 10px"></div>
    </div>
    <!-- 立即打赏查询按钮 -->
    <div class="queryBtn">
      <van-button type="info" round @click="toPayBtn">{{ textBtn }}</van-button>
    </div>
    <!-- 检测内容 -->
    <div class="testContent">
      <!-- 检测内容 中文title -->
      <p class="testTitle">
        <img src="../../src/assets/Home2/ld.png" alt="" />
        检测内容说明
        <img src="../../src/assets/Home2/ld.png" alt="" />
      </p>
      <!-- 检测内容 英文title -->
      <p class="englishTitle">Description of test content</p>
      <!--检测内容 八个模块 -->
      <div class="testModular">
        <div class="item">
          <img src="../assets/Home2/1.png" alt="" />
          <span>背景优化建议</span>
        </div>
        <div class="item">
          <img src="../assets/Home2/2.png" alt="" />
          <span>背景知识普及</span>
        </div>
        <div class="item">
          <img src="../assets/Home2/3.png" alt="" />
          <span>债务纠纷问题</span>
        </div>
        <div class="item">
          <img src="../assets/Home2/4.png" alt="" />
          <span>优化技巧指南</span>
        </div>
        <div class="item">
          <img src="../assets/Home2/5.png" alt="" />
          <span>黑名单风险排查</span>
        </div>
        <div class="item">
          <img src="../assets/Home2/6_7.png" alt="" />
          <span>风险信息扫描</span>
        </div>
        <div class="item">
          <img src="../assets/Home2/6_7.png" alt="" />
          <span>欺诈风险监控</span>
        </div>
        <div class="item">
          <img src="../assets/Home2/8.png" alt="" />
          <span>司法失信咨询</span>
        </div>
      </div>
    </div>
    <!-- 用户评价 -->
    <div class="testContent">
      <!-- 检测内容 中文title -->
      <p class="testTitle">
        <img src="../../src/assets/Home2/ld.png" alt="" />
        用户评价
        <img src="../../src/assets/Home2/ld.png" alt="" />
      </p>
      <p class="englishTitle">User comments</p>
      <!-- 滚动评论 -->
      <div class="scrollComments">
        <HbSwpItme></HbSwpItme>
      </div>
      <div class="floot">
        <van-field
          v-model="message"
          rows="2"
          size="32"
          autosize
          label="发表评论"
          type="textarea"
          maxlength="20"
          placeholder="请输入你想说的话"
          show-word-limit
        />
        <P class="boxbtn">
          <span class="spanTips">温馨提示：本报告非央行征信报告</span>
          <van-button type="info" size="small" @click="submitComments"
            >提交评论</van-button
          >
        </P>
      </div>
    </div>
    <!-- 四个导航按钮 定位  -->
    <div class="navigationButtons">
      <rightNavigation :showReport="navigationHidden"></rightNavigation>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Field, Toast, NoticeBar } from 'vant';
Vue.use(Field);
Vue.use(Toast);
Vue.use(NoticeBar);

import HbSwpItme from '../components/HbSwpItme.vue';

import rightNavigation from '../components/rightNavigation.vue';

import { getUserInfo, button } from '../api/getUserInfo';
import rollbuy from '../components/rollbuy.vue';

export default {
  name: 'HB',
  components: {
    HbSwpItme,
    rightNavigation,
    rollbuy,
  },
  data() {
    return {
      // 是否勾选协议1
      agreementChecked: true,
      // 是否勾选协议2
      agreementChecked2: false,
      // 按钮文字
      textBtn: '立即查询',
      bestowal: '', //赠险类型
      content: '本人自愿免费领取价值999元个人债务规划方案',
      // 发布评论
      message: '',
      // 是否开启导航第四个导航
      navigationHidden: {
        // 报告样例
        reportSample: {
          show: true,
          styleCss: '',
        },
        // 我的报告
        myReport: {
          show: true,
          styleCss: '',
        },
        // 客服
        service: {
          show: true,
          styleCss: 'background-color:#f97f4a',
        },
        // 建议
        suggest: {
          show: true,
          styleCss: '',
        },
      },
      xxdd: [
        {
          img: require('../assets/Home2/avatar1.png'),
          text: '1分钟前有人已购买',
        },
        {
          img: require('../assets/Home2/avatar2.png'),
          text: '46s前有人已购买',
        },
        {
          img: require('../assets/Home2/avatar3.png'),
          text: '23s前有人已购买',
        },
        { img: require('../assets/Home2/avatar4.png'), text: '8s前有人已购买' },
      ],
      // 手机号
      userPhone: null,
      // 用户名
      userName: null,
      // 用户身份证
      userId: null,
      // 产品ID
      cid: null,
      // 模板ID
      templateId: 2,
      bestowalContent: '本人自愿免费领取价值999元个人债务规划方案',
      agreementName: '',
      isStop: false,
    };
  },
  created() {
    this.cid = this.$route.query.cid;
    this.templateId = this.$route.query.templateId;
  },
  mounted() {
    // 执行按钮文案
    this.buttonTxt({ cid: this.cid });
  },
  methods: {
    // 三个协议
    gotoprotocol() {
      this.$router.push({
        path: '/queryProtocol',
      });
    },
    insuranceProtocol() {
      this.$router.push({
        path: '/insuranceProtocol',
        query: {
          cid: this.cid,
        },
      });
    },
    userProtocol() {
      this.$router.push({
        path: '/userProtocol',
      });
    },
    // 提交评论
    submitComments() {
      if (this.isStop) {
        return Toast("本系统暂停运营，请及时联系商务注册使用最新的系统！");
      }
      if (!this.message) {
        return Toast('请输入你想说的话');
      }
      Toast.loading({
        message: '提交中...',
        forbidClick: true,
      });
      let time1 = setTimeout(() => {
        Toast.success('提交成功');
        this.message = '';
        clearTimeout(time1);
      }, 2000);
    },
    // 立即查询
    toPayBtn() {
      if (this.isStop) {
        return Toast("本系统暂停运营，请及时联系商务注册使用最新的系统！");
      }
      let Toast1 = Toast.loading({
        duration: 0,
        message: '查询中...',
        forbidClick: true,
      });
      // 身份证号正则
      let _IDRe18 =
        /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      let _IDre15 =
        /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/;
      // 手机号正则
      let reg = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
      var namereg = /^[\u4E00-\u9FA5A-Za-z\s]+(·[\u4E00-\u9FA5A-Za-z]+)*$/;
      if (!namereg.test(this.userName) || !this.userName) {
        return Toast('请正确填写您的姓名');
      }
      if (!reg.test(this.userPhone)) {
        return Toast('请填写正确的手机号码');
      }
      if (!this.agreementChecked) {
        return Toast('请同意《报告查询服务协议》');
      }
      if (_IDRe18.test(this.userId) || _IDre15.test(this.userId)) {
        // 这里写逻辑
        let cdata = {
          username: this.userName,
          mobile: this.userPhone,
          idCard: this.userId,
          bestowal: this.agreementChecked2,
          cid: this.cid,
        };
        getUserInfo(cdata)
          .then((res) => {
            if (res.data.code == 200) {
              Toast1.clear();
              // status true 正常用户 false 用户已拉黑
              if (res.data.data.status) {
                // orderNo 存在 24小时内已经购买过的 去详情
                if (res.data.data.orderNo == null) {
                  // 未支付的 去支付
                  this.$router.push({
                    path: '/toBePaid',
                    query: {
                      userId: res.data.data.userId,
                      cid: this.cid,
                      idCard: this.userId,
                      userName: this.userName,
                    },
                  });
                } else {
                  this.$router.push({
                    path: '/prosperity',
                    query: {
                      orderNo: res.data.data.orderNo,
                      cid: this.cid,
                      templateId: this.templateId,
                    },
                  });
                }
              } else {
                //  用户已拉黑
                Toast(res.data.msg);
              }
            } else {
              Toast(res.data.msg);
            }
          })
          .catch((err) => {
            Toast(err);
          });
      } else {
        return Toast('身份证填写有误');
      }
    },
    buttonTxt(data) {
      button(data)
        .then((res) => {
          if (res.data.code == 200) {
            this.textBtn = res.data.data.buttonText || '立即查询';
            this.bestowal = res.data.data.bestowalType;
            this.bestowalContent = res.data.data.bestowalContent;
            this.agreementName = res.data.data.agreementName;
          } else {
             //按钮禁用
             this.isStop = true;
            this.navigationHidden = {
              reportSample: {
                show: false,
                styleCss: "",
              },
              // 我的报告
              myReport: {
                show: false,
                styleCss: "",
              },
              // 客服
              service: {
                show: true,
                styleCss: "",
              },
              // 建议
              suggest: {
                show: false,
                styleCss: "",
              },
            };
            Toast(res.data.msg);
          }
        })
        .catch((err) => {
          Toast(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.HB {
  max-width: 750px;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  background-image: url('../assets/Home2/HBbackground.jpg');
  background-repeat: no-repeat !important;
  background-position: 0 0 !important;
  background-size: contain !important;
  background-color: #fff;
  // 四个导航按钮 navigationButtons
  .navigationButtons {
    position: fixed;
    top: 550px;
    right: 0;
  }
  // 滚动购买
  .rollbuy {
    width: 100%;
    height: 450px;
    // background: #000;
    // opacity: 0.3;
    .xxdw {
      width: 400px;
      height: 80px;
      overflow: hidden;
      position: absolute;
      top: 35px;
      right: 0;
    }
  }
  // 信息填写
  .info {
    width: 92%;
    min-height: 200px;
    margin: 0 auto;
    border-radius: 20px;
    background: #fff;
    -webkit-box-shadow: 0px 5px 5px #c8c8c8;
    -moz-box-shadow: 0px 5px 5px #c8c8c8;
    box-shadow: 0px 5px 5px #c8c8c8;
    margin-bottom: 20px;
    .title {
      width: 100%;
      height: 88px;
      line-height: 88px;
      font-size: 26px;
      color: #ff8787;
      font-weight: bold;
      text-align: center;
    }
    // 输入框
    .inputbox {
      width: 92%;
      height: 66px;
      padding: 13px 0;
      background: #f0f0f0;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      border-radius: 10px;
      margin-bottom: 20px;
      img {
        width: 66px;
        height: 66px;
      }
      input {
        width: calc(100% - 100px);
        height: 66px;
        background: #f0f0f0;
        border: none;
        font-size: 26px;
      }
    }
    // 协议样式 开始
    .agreement {
      width: 92%;
      // height: 30px;
      margin: 10px auto;
      // margin-bottom: 5px;
      /* background: skyblue; */
      display: flex;
      align-items: center;
      font-size: 24px;
    }
    .agreement1 {
      width: 92%;
      display: flex;
      margin: 20px auto;
    }
    .twoAgreement {
      width: 92%;
      // height: 50px;
      text-align: left;
    }
    // 协议样式 结束
  }
  // 立即打赏查询
  .queryBtn {
    width: 100%;
    height: 120px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-around;
    button {
      width: 92%;
      height: 88px;
      font-size: 32px;
      background-color: #1f77f6;
      border: none;
      -webkit-box-shadow: 0px 5px 5px #c8c8c8;
      -moz-box-shadow: 0px 5px 5px #c8c8c8;
      box-shadow: 0px 5px 5px #c8c8c8;
    }
  }
  // 检测内容
  .testContent {
    width: 92%;
    min-height: 460px;
    padding: 40px 0;
    margin: 30px auto 50px auto;
    background: #fff;
    border-radius: 10px;
    -webkit-box-shadow: 0px 3px 12px #c8c8c8;
    -moz-box-shadow: 0px 3px 12px #c8c8c8;
    box-shadow: 0px 3px 12px #c8c8c8;
    // 检测内容 中文title
    .testTitle {
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 32px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 32px;
        height: 8px;
        margin: 0 10px;
      }
    }
    // 检测内容 英文title
    .englishTitle {
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-size: 26px;
      text-align: center;
      color: #888888;
    }
    // 检测内容八个模块 testModular
    .testModular {
      width: 100%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .item {
        height: 88px;
        width: 40%;
        height: 88px;
        color: #666;
        float: left;
        font-size: 28px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img {
          width: 66px;
          height: 66px;
        }
      }
    }
    // 滚动评论 scrollComments
    .scrollComments {
      height: auto;
    }
    // 最后一部分
    .floot {
      padding-bottom: 30px;
      /deep/ .van-field__label {
        font-size: 28px !important;
        color: #000;
        font-weight: bold;
      }
      .boxbtn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 48px;
        height: 100px;
        .spanTips {
          color: red;
          font-size: 26px;
        }
        button {
          color: #fff !important;
        }
      }
    }
  }
}
</style>
